exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-ambience-tsx": () => import("./../../../src/pages/ambience.tsx" /* webpackChunkName: "component---src-pages-ambience-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-bookings-tsx": () => import("./../../../src/pages/bookings.tsx" /* webpackChunkName: "component---src-pages-bookings-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portraits-tsx": () => import("./../../../src/pages/portraits.tsx" /* webpackChunkName: "component---src-pages-portraits-tsx" */),
  "component---src-pages-prints-tsx": () => import("./../../../src/pages/prints.tsx" /* webpackChunkName: "component---src-pages-prints-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */)
}

